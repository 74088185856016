* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}
html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
input:focus:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.fade-enter-active {
  transition: opacity 0.2s ease-in;
}
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@font-face {
  font-family: 'HelveticaNeueCyr Roman';
  src: url('../fonts/HelveticaNeueCyr-Roman.otf');
}
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('../fonts/HelveticaNeueCyr-Roman.otf');
}
@font-face {
  font-family: 'HelveticaNeueCyr Light';
  src: url('../fonts/HelveticaNeueCyr-Light.otf');
}
@font-face {
  font-family: 'HelveticaNeueCyr Thin';
  src: url('../fonts/HelveticaNeueCyr-Thin.otf');
}
body {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
  background-color: #E4E4E4;
  background-attachment: fixed;
  font-family: 'HelveticaNeue-Light', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  cursor: pointer;
}
/* Portrait layout (default) */
button.alert {
  background: #cc3333;
  color: #fff;
}
button.alert:active,
button.alert:focus {
  background: #cc3333;
  color: #fff;
}
button.alert:hover {
  background: #883333;
  color: #fff;
}
h1 {
  font-size: 24px;
  font-weight: normal;
  margin: 0px;
  overflow: visible;
  padding: 0px;
  text-align: center;
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
.icon-caret-down {
  width: 0.57142857em;
}
.icon-caret-up {
  width: 0.57142857em;
}
.icon-sort {
  width: 0.57142857em;
}
.icon-exclamation-circle {
  width: 0.85714286em;
}
.icon-floors {
  width: 0.97753906em;
}
.icon-login {
  width: 0.7578125em;
}
.icon-notification {
  width: 0.95800781em;
}
.icon-type-bulb {
  width: 0.703125em;
}
.icon-type-valves-bottom {
  width: 1.15234375em;
}
.icon-type-valves-top {
  width: 1.15234375em;
}
.btn,
.form-control {
  border-radius: 0;
  border: 0;
}
.btn:active,
.form-control:active,
.btn:focus,
.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}
#main-navigation {
  background: #006ce4;
  /* Old browsers */
  background: -moz-linear-gradient(180deg, #218fdd 0%, #1ba7db);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, #218fdd 0%, #1ba7db);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, #218fdd 0%, #1ba7db);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 5px 5px;
  position: fixed;
  z-index: 6;
  left: 0;
  width: 100%;
  right: 0;
}
#main-navigation .icon {
  width: 1.7em;
  height: 1.7em;
  line-height: 1.7em;
}
#main-navigation .btn {
  padding: 6px;
  background: none;
  color: #fff;
  transition: color 0.2 ease;
}
#main-navigation .btn:hover {
  color: #091244;
}
#main-navigation.rotate-90 {
  padding-top: 5px;
  left: 0;
  height: 100%;
  width: 50px;
  right: auto;
  bottom: 0;
}
#main-navigation.rotate-90 .btn {
  float: none !important;
}
#main-navigation.rotate-90 .row {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}
#main-navigation.rotate-90 .col {
  float: none;
  padding: 0;
  height: 50%;
  width: 100%;
  text-align: center;
}
#main-navigation.rotate-90 .col.left {
  height: 70%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 15px 0;
}
#main-navigation.rotate-90 .col.left .btn {
  transform: rotate(-90deg);
  margin-top: 0;
  margin-bottom: 30px;
}
#main-navigation.rotate-90 .col.left .btn span {
  display: block;
}
#main-navigation.rotate-90 .col.right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 30%;
}
#main-navigation.rotate-90 .col.right .btn {
  margin-top: 0;
  margin-bottom: 0;
}
#main-navigation.rotate-90 .col.right .btn span {
  display: block;
}
#main-navigation.rotate-90 .icon {
  transform: rotate(0deg);
}
#main-toolbar {
  background: #fff;
  color: #218fdd;
  padding: 0;
  position: fixed;
  left: 0;
  height: 80px;
  width: 100%;
  right: 0;
  bottom: 0;
  padding-bottom: calc(80px + var(--safe-area-inset-bottom));
  /* optional: show position indicator in red */
}
#main-toolbar ::-webkit-scrollbar {
  width: 0;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
  display: none !important;
}
#main-toolbar ::-webkit-scrollbar-thumb {
  background: transparent;
  display: none !important;
}
#main-toolbar .VueCarousel-pagination {
  display: none !important;
}
#main-toolbar.rotate-0 {
  padding-bottom: 0;
}
#main-toolbar.rotate-0 .icon {
  transform: rotate(0deg);
}
#main-toolbar.rotate-90 {
  padding-bottom: 0;
  left: auto;
  height: 100%;
  width: 80px;
  right: 0;
  bottom: 0;
}
#main-toolbar.rotate-90 .VueCarousel,
#main-toolbar.rotate-90 #toolbar-carousel {
  height: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  width: 80px;
}
#main-toolbar.rotate-90 #toolbar-carousel .inside {
  min-width: auto !important;
  width: 100% !important;
  height: 100vh;
}
#main-toolbar.rotate-90 .VueCarousel-wrapper {
  height: 100%;
  width: 80px;
  overflow: hidden;
  overflow-x: hidden;
  transform: none !important;
  transition: none !important;
  overflow-y: visible;
}
#main-toolbar.rotate-90 .VueCarousel-inner {
  flex-direction: column;
  padding-bottom: 100px;
}
#main-toolbar.rotate-90 .icon {
  transform: rotate(0deg);
  width: 80px;
  height: 120px;
}
#main-toolbar.rotate--90 {
  padding-bottom: 0;
}
#main-toolbar.rotate--90 .icon {
  transform: rotate(-90deg);
}
#main-toolbar #toolbar-carousel {
  overflow-x: scroll;
}
#main-toolbar .icon {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-self: center;
  width: 120px;
  float: left;
  height: 80px;
  padding: 10px;
  text-align: center;
  border-right: 1px solid #ccc;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
}
#main-toolbar .icon .status-indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 69%;
  top: 60%;
  background: #999;
  border-radius: 50%;
  line-height: 11px;
  font-size: 8px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
#main-toolbar .icon.active {
  box-shadow: inset 0 4px #218fdd;
  background: #218fdd;
  background: -moz-linear-gradient(180deg, #218fdd 0%, #1ba7db);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, #218fdd 0%, #1ba7db);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, #218fdd 0%, #1ba7db);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
#main-toolbar .icon.active svg {
  fill: #fff;
}
.btn-title {
  line-height: 1.7em;
  margin-top: 2px;
}
.btn-title svg {
  float: left;
  margin-right: 5px;
}
.btn-danger-flat {
  background-color: #c64a3f;
  color: #fff;
  border: 1px solid #c64a3f;
}
.btn-danger-flat:hover {
  background-color: #a13127;
  border: 1px solid #a13127;
}
.btn-success-flat {
  background-color: #8DC63F;
  color: #fff;
  border: 1px solid #8DC63F;
}
.btn-success-flat:hover {
  background-color: #78aa31;
  border: 1px solid #78aa31;
}
.houses-view {
  background: #fff;
  padding: 50px 0;
  color: #ccc;
  font-size: 14px;
  position: fixed;
  max-width: 450px;
  left: -200%;
  width: 90%;
  height: 100%;
  z-index: 5;
  transition: left 0.2s ease;
}
.houses-view.rotate-90 {
  margin-left: 50px;
  right: 80px;
  width: auto;
  padding: 15px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.houses-view.rotate-90 .alarms .inside,
.houses-view.rotate-90 .alarms .title {
  padding-left: 0;
}
.houses-view.rotate-90 .scroll-horizontal {
  margin-left: -15px;
  padding-bottom: 25px;
}
.houses-view.rotate-90 .scroll-horizontal .scrollable {
  overflow-x: scroll;
}
.houses-view.active {
  left: 0;
  transition: left 0.2s ease;
}
.houses-view .vertical-align {
  height: 100%;
}
.houses-view .vertical-align.space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.houses-view .alarms .inside {
  padding: 0 15px;
}
.houses-view .scroll-horizontal {
  margin-left: 0;
  margin-top: 10px;
}
.houses-view .scroll-horizontal ::-webkit-scrollbar-track {
  background-color: transparent;
}
.houses-view .scroll-horizontal ::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background-color: transparent;
}
.houses-view .scroll-horizontal ::-webkit-scrollbar-thumb {
  background-color: transparent;
  background: transparent;
}
.houses-view .scroll-horizontal .scrollable {
  overflow-x: scroll;
}
.houses-view .scroll-horizontal .inside {
  padding: 0;
}
.houses-view .scroll-horizontal .box {
  width: 100px;
  float: left;
  color: #666;
  text-align: center;
  border: 0;
}
.houses-view .scroll-horizontal .box .icon {
  fill: #666;
  display: block;
  margin: 5px auto;
  width: 35px;
  height: 30px;
}
.houses-view .scroll-horizontal .box .text {
  font-size: 12px;
  color: #666;
}
.houses-view .title {
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 15px;
  padding: 0 15px;
  color: #333;
}
.houses-view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  padding-bottom: 140px;
  height: 50%;
}
.houses-view ul > li {
  display: block;
  padding: 0 15px;
}
.houses-view ul > li.active {
  background: #949494;
  color: #fff;
}
.houses-view ul > li.active > a {
  color: #fff;
  border-bottom: 1px solid #949494;
}
.houses-view ul > li > a {
  display: block;
  padding: 20px 15px;
  color: #666;
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
}
.houses-view ul ::-webkit-scrollbar-track {
  background-color: transparent;
}
.houses-view ul ::-webkit-scrollbar {
  width: 0;
  /* remove scrollbar space */
  background-color: transparent;
}
.houses-view ul ::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.floors-view {
  background: #fff;
  padding: 50px 0;
  color: #ccc;
  font-size: 14px;
  position: fixed;
  right: -100%;
  width: 90%;
  max-width: 450px;
  height: 100%;
  z-index: 5;
  transition: right 0.2s ease;
}
.floors-view.rotate-90 {
  margin-right: 0;
  left: -100%;
}
.floors-view.rotate-90.active {
  left: 50px;
  width: auto;
  padding: 15px;
  right: 80px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  transition: left 0.2s ease;
}
.floors-view.active {
  right: 0;
  transition: right 0.2s ease;
}
.floors-view .title {
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 15px;
  padding: 0 15px;
  color: #333;
}
.floors-view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 140px;
}
.floors-view ul > li {
  display: block;
  padding: 0 15px;
}
.floors-view ul > li.active {
  background: #949494;
  color: #fff;
}
.floors-view ul > li.active > a {
  color: #fff;
  border-bottom: 1px solid #949494;
}
.floors-view ul > li > a {
  display: block;
  padding: 20px 15px;
  color: #666;
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
}
.floors-view ul ::-webkit-scrollbar-track {
  background-color: transparent;
}
.floors-view ul ::-webkit-scrollbar {
  background-color: transparent;
}
.floors-view ul ::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.notifications-view {
  background: #fff;
  padding: 50px 0;
  color: #ccc;
  font-size: 14px;
  position: fixed;
  right: -100%;
  width: 90%;
  max-width: 450px;
  height: 100%;
  z-index: 5;
  transition: right 0.2s ease;
}
.notifications-view.rotate-90 {
  margin-right: 80px;
  left: -100%;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.notifications-view.rotate-90.active {
  left: 50px;
  right: auto;
  padding: 0;
  width: auto;
  max-width: 450px;
  transition: left 0.2s ease;
}
.notifications-view.active {
  right: 0;
  transition: right 0.2s ease;
}
.notifications-view .title {
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 15px;
  padding: 0 15px;
  color: #333;
}
.notifications-view .scroll-horizontal {
  overflow-y: scroll;
  margin-left: -15px;
  margin-top: 10px;
}
.notifications-view .scroll-horizontal ::-webkit-scrollbar-track {
  background-color: transparent;
}
.notifications-view .scroll-horizontal ::-webkit-scrollbar {
  background-color: transparent;
}
.notifications-view .scroll-horizontal ::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.notifications-view .scroll-horizontal .inside {
  padding: 0;
}
.notifications-view .scroll-horizontal .box {
  width: 100px;
  float: left;
  color: #fff;
  text-align: center;
  border: 0;
}
.notifications-view .scroll-horizontal .box .icon {
  fill: #FFF;
  display: block;
  margin: 5px auto;
  width: 35px;
  height: 30px;
}
.notifications-view .scroll-horizontal .box .hour {
  font-size: 12px;
  color: #fff;
}
.notifications-view .metereo-block {
  width: 100%;
  height: 370px;
  background: #218fdd;
  background: linear-gradient(0deg, #218fdd 0%, #1ba7db);
  background: -moz-linear-gradient(0deg, #218fdd 0%, #1ba7db);
  background: -webkit-linear-gradient(0deg, #218fdd 0%, #1ba7db);
  padding: 15px;
  text-align: center;
  color: #FFF;
}
.notifications-view .metereo-block small,
.notifications-view .metereo-block .small {
  font-size: 10px;
  line-height: 1em;
  margin-bottom: 5px;
}
.notifications-view .metereo-block hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #b5b5b5;
}
.notifications-view .metereo-block .w-listing {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.notifications-view .metereo-block .w-listing li {
  display: block;
  padding: 0;
  color: #fff;
  font-size: 14px;
  margin: 0;
  border: 0;
}
.notifications-view .metereo-block .flex-cols {
  display: flex;
}
.notifications-view .metereo-block .flex-cols .col {
  flex-grow: 1;
}
.notifications-view .metereo-block .currentDate {
  font-size: 13px;
}
.notifications-view .metereo-block .currentTemp {
  font-size: 2.3em;
  font-family: 'HelveticaNeueCyr Thin', sans-serif;
  font-weight: 300;
}
.notifications-view ul {
  list-style: none;
  overflow-y: scroll;
  height: 50%;
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
}
.notifications-view ul > li {
  display: block;
  color: #999;
  font-size: 13px;
  margin-bottom: 0;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}
.notifications-view ul > li .title {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
.notifications-view ul > li .title .badge.system {
  background: #cc3333;
  color: #fff;
}
.notifications-view ul > li .title .badge.new {
  background: #33cc47;
  color: #fff;
}
.notifications-view ul > li.new {
  font-weight: bold;
}
.notifications-view ul ::-webkit-scrollbar-track {
  background-color: transparent;
}
.notifications-view ul ::-webkit-scrollbar {
  background-color: transparent;
}
.notifications-view ul ::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.side_menu_blackbg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  bottom: 0;
  z-index: 4;
  background: rgba(255, 255, 255, 0.7);
}
.side_menu_blackbg.active {
  display: block;
}
.map-view::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent !important;
  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
.map-view::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
  background: transparent !important;
}
.pinchable {
  touch-action: manipulation;
}
.map-view {
  overflow: scroll;
  width: 100%;
  display: block;
  display: none;
  overflow-y: scroll;
  top: 50px;
  bottom: 80px;
  transition: 0.2s ease;
  position: absolute;
  background: #218fdd;
  background: -moz-linear-gradient(0deg, #218fdd 0%, #1ba7db);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(0deg, #218fdd 0%, #1ba7db);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(0deg, #218fdd 0%, #1ba7db);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* optional: show position indicator in red */
}
.map-view.stickToBottom {
  bottom: 0;
}
.map-view.rotate-90 {
  top: 0;
  bottom: 0;
  left: 50px;
  width: auto;
  right: 80px;
  transition: none !important;
}
.map-view.rotate-90.stickToBottom {
  right: 0;
}
.map-view.active {
  display: block;
  transition: 0.2s ease;
}
.map-view.zoomed {
  transform: scale(1.5);
  padding: 100px;
}
.map-view ::-webkit-scrollbar {
  width: 0;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}
.map-view ::-webkit-scrollbar-thumb {
  background: transparent;
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 108, 228, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 108, 228, 0.8);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(0, 108, 228, 0);
    box-shadow: 0 0 0 20px rgba(0, 108, 228, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 108, 228, 0);
    box-shadow: 0 0 0 0 rgba(0, 108, 228, 0);
  }
}
.map-widgets {
  position: absolute;
  width: 100%;
  height: 100%;
}
.map-widgets .icon-widget {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  background: #fff;
  border-radius: 15px;
  border: 2px solid #fff;
  padding: 5px;
  display: block;
  width: auto;
  text-transform: none;
  font-size: 13px;
  z-index: 2;
  min-width: 30px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
}
.map-widgets .icon-widget.motion-sensor-icon {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  animation: none;
  transition: 0.2s ease;
  box-shadow: 0 0 0 0 rgba(0, 108, 228, 0);
  min-width: 0;
}
.map-widgets .icon-widget.motion-sensor-icon.active {
  animation: pulse 1s infinite;
  background: #006ce4;
  color: #fff;
}
.map-widgets .icon-widget.door-status-icon {
  width: 10px;
  height: 10px;
  border: 0;
  animation: none;
  transition: 0.2s ease;
  min-width: 0;
  color: #fff;
  background: #cc3333;
}
.map-widgets .icon-widget.door-status-icon.active {
  background: #8DC63F;
  color: #fff;
}
.map-widgets .icon-widget.clickable-offset:before {
  content: '';
  position: absolute;
  background: rgba(248, 143, 6, 0);
  width: 210%;
  height: 210%;
  display: block;
  z-index: -1;
}
.map-widgets .icon-widget.icon-type-clock .icon {
  width: 35px;
  height: 35px;
}
.map-widgets .icon-widget.icon-type-clock .icon svg {
  width: 35px;
  height: 35px;
}
.map-widgets .icon-widget.icon-type-buffer-water,
.map-widgets .icon-widget.icon-type-buffer-heat-pump,
.map-widgets .icon-widget.icon-type-buffer-system,
.map-widgets .icon-widget.icon-type-buffer-kotelno,
.map-widgets .icon-widget.icon-type-buffer-boiler {
  padding: 10px;
  border-radius: 25px;
}
.map-widgets .icon-widget.icon-type-buffer-water.active,
.map-widgets .icon-widget.icon-type-buffer-heat-pump.active,
.map-widgets .icon-widget.icon-type-buffer-system.active,
.map-widgets .icon-widget.icon-type-buffer-kotelno.active,
.map-widgets .icon-widget.icon-type-buffer-boiler.active {
  box-shadow: 0 0 0 2px #006ce4;
}
.map-widgets .icon-widget.icon-type-buffer-water .icon,
.map-widgets .icon-widget.icon-type-buffer-heat-pump .icon,
.map-widgets .icon-widget.icon-type-buffer-system .icon,
.map-widgets .icon-widget.icon-type-buffer-kotelno .icon,
.map-widgets .icon-widget.icon-type-buffer-boiler .icon {
  padding: 0;
  width: 60px;
  height: 60px;
  margin: 0;
}
.map-widgets .icon-widget.icon-type-buffer-water .icon svg,
.map-widgets .icon-widget.icon-type-buffer-heat-pump .icon svg,
.map-widgets .icon-widget.icon-type-buffer-system .icon svg,
.map-widgets .icon-widget.icon-type-buffer-kotelno .icon svg,
.map-widgets .icon-widget.icon-type-buffer-boiler .icon svg {
  width: 100%;
  height: 100%;
}
.map-widgets .icon-widget.icon-type-pir {
  padding: 0;
  border-radius: 25px;
}
.map-widgets .icon-widget.icon-type-pir.active {
  box-shadow: 0 0 0 2px #006ce4;
}
.map-widgets .icon-widget.icon-type-pir .icon {
  padding: 0;
  width: 45px;
  height: 45px;
  margin: 0;
}
.map-widgets .icon-widget.icon-type-pir .icon svg {
  width: 100%;
  height: 100%;
}
.map-widgets .icon-widget.icon-type-humidity {
  min-width: 110px;
}
.map-widgets .icon-widget.icon-type-humidity .text {
  margin-top: 4px;
  float: left;
}
.map-widgets .icon-widget.icon-type-humidity .icon {
  width: 25px;
  height: 25px;
  float: left;
  margin-bottom: 0;
  margin-right: 7px;
}
.map-widgets .icon-widget.icon-type-humidity .icon svg {
  width: 25px;
  height: 25px;
}
.map-widgets .icon-widget.icon-type-drop,
.map-widgets .icon-widget.icon-type-water-spray {
  width: 60px;
  height: 60px;
}
.map-widgets .icon-widget.icon-type-drop .over-icon,
.map-widgets .icon-widget.icon-type-water-spray .over-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  padding: 5px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.map-widgets .icon-widget.icon-type-drop .over-icon .status-indicator,
.map-widgets .icon-widget.icon-type-water-spray .over-icon .status-indicator {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 79%;
  top: 70%;
  background: #999;
  border-radius: 50%;
  line-height: 20px;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
.map-widgets .icon-widget.icon-type-drop .over-icon .status-indicator.active,
.map-widgets .icon-widget.icon-type-water-spray .over-icon .status-indicator.active {
  background: #66a55c;
  transition: 0.3s ease;
}
.map-widgets .icon-widget.icon-type-drop .over-icon svg,
.map-widgets .icon-widget.icon-type-water-spray .over-icon svg {
  width: 100%;
  height: 100%;
  fill: #000;
  stroke-width: 0;
}
.map-widgets .icon-widget.icon-type-area {
  background: transparent !important;
  z-index: 0;
  border: 0;
}
.map-widgets .icon-widget.icon-type-area .icon {
  width: auto;
  height: auto;
}
.map-widgets .icon-widget.icon-type-area .icon svg {
  width: auto;
  height: auto;
  fill: none;
  stroke: #00F0FF;
  stroke-width: 5px;
}
.map-widgets .icon-widget.icon-type-params {
  background: transparent;
  border: 0;
  width: auto;
  height: auto;
}
.map-widgets .icon-widget.icon-type-window {
  padding: 5px;
}
.map-widgets .icon-widget.icon-type-window .icon {
  width: 23px;
  height: 23px;
  margin-bottom: 0;
  margin-right: 0;
}
.map-widgets .icon-widget.icon-type-window .icon .status-indicator {
  left: 70%;
  top: 67%;
}
.map-widgets .icon-widget.icon-type-window .icon svg {
  width: 22px;
  height: 22px;
}
.map-widgets .icon-widget.icon-type-window-bold {
  padding: 4px;
}
.map-widgets .icon-widget.icon-type-window-bold .icon {
  width: 25px;
  height: 25px;
  margin-bottom: 0;
  margin-right: 0;
}
.map-widgets .icon-widget.icon-type-window-bold .icon .status-indicator {
  left: 70%;
  top: 65%;
}
.map-widgets .icon-widget.icon-type-window-bold .icon svg {
  width: 24px;
  height: 24px;
}
.map-widgets .icon-widget.icon-type-stove {
  padding: 3px;
}
.map-widgets .icon-widget.icon-type-stove .icon {
  width: 20px;
  height: 20px;
  margin: 3px;
}
.map-widgets .icon-widget.icon-type-stove .icon svg {
  width: 20px;
  height: 20px;
}
.map-widgets .icon-widget.icon-type-stove.alert {
  background: #cc3333;
  border-color: #cc3333;
  color: #fff;
}
.map-widgets .icon-widget.icon-type-stove.alert .icon svg {
  fill: #FFF;
}
.map-widgets .icon-widget.icon-type-steam {
  padding: 3px;
}
.map-widgets .icon-widget.icon-type-steam .icon {
  width: 20px;
  height: 20px;
  margin: 3px;
}
.map-widgets .icon-widget.icon-type-steam .icon svg {
  width: 20px;
  height: 20px;
}
.map-widgets .icon-widget.icon-type-steam.alert {
  background: #cc3333;
  border-color: #cc3333;
  color: #fff;
}
.map-widgets .icon-widget.icon-type-steam.alert .icon svg {
  fill: #FFF;
}
.map-widgets .icon-widget.circleDimmValue,
.map-widgets .icon-widget.icon-type-led.circleDimmValue {
  background: #006ce4;
  color: #fff;
  min-width: 80px ;
}
.map-widgets .icon-widget.circleDimmValue .icon,
.map-widgets .icon-widget.icon-type-led.circleDimmValue .icon {
  width: auto;
}
.map-widgets .icon-widget.icon-type-led {
  min-width: 100px;
  border-radius: 0;
  padding: 0;
  border: 1px solid #ccc;
}
.map-widgets .icon-widget.icon-type-led .icon {
  height: auto;
  width: 150px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}
.map-widgets .icon-widget.icon-type-led .icon .status-indicator {
  top: 4px;
  right: 0;
}
.map-widgets .icon-widget.icon-type-led.active {
  border-color: #006ce4;
  color: #006ce4;
  box-shadow: 0 0 30px #006ce4;
}
.map-widgets .icon-widget.active {
  border-color: #006ce4;
}
.map-widgets .icon-widget.inactive {
  border-color: #A3A3A3;
}
.map-widgets .icon-widget.icon-type-bulb {
  padding: 3px;
  min-width: 23px;
  box-shadow: 0 0 50px rgba(241, 226, 56, 0);
}
.map-widgets .icon-widget.icon-type-bulb .icon {
  margin: 0;
  width: 23px;
  height: 23px;
}
.map-widgets .icon-widget.icon-type-bulb .icon svg {
  width: 23px;
  height: 23px;
}
.map-widgets .icon-widget.icon-type-bulb.active {
  transition: 0.3s ease;
}
.map-widgets .icon-widget.icon-type-valves-top,
.map-widgets .icon-widget.icon-type-valves-bottom {
  width: auto;
  min-width: 30px;
}
.map-widgets .icon-widget.icon-type-valves-top .icon,
.map-widgets .icon-widget.icon-type-valves-bottom .icon {
  margin-bottom: 0;
}
.map-widgets .icon-widget.icon-type-valves-top.valves-info,
.map-widgets .icon-widget.icon-type-valves-bottom.valves-info {
  background: transparent;
  border-radius: 0;
  display: block;
  border: 0;
  padding: 0;
  width: auto;
  height: auto;
  min-width: 30px;
}
.map-widgets .icon-widget.icon-type-valves-top.valves-info .btn,
.map-widgets .icon-widget.icon-type-valves-bottom.valves-info .btn {
  font-size: 20px;
  padding: 10px 15px;
  min-width: 100px;
  margin: 0;
}
.map-widgets .icon-widget.icon-type-air-conditioner {
  width: 53px;
}
.map-widgets .icon-widget.icon-type-floor,
.map-widgets .icon-widget.icon-type-air {
  width: 100px;
}
.map-widgets .icon-widget.icon-type-floor .icon,
.map-widgets .icon-widget.icon-type-air .icon {
  margin-right: 16px;
}
.map-widgets .icon-widget.icon-type-floor .info,
.map-widgets .icon-widget.icon-type-air .info {
  float: left;
}
.map-widgets .icon-widget.icon-type-floor .btn,
.map-widgets .icon-widget.icon-type-air .btn {
  font-size: 12px;
  padding: 3px 7px;
  display: block;
  width: 100%;
  margin: 0;
}
.map-widgets .icon-widget.icon-type-floor.vlajnost-indicator,
.map-widgets .icon-widget.icon-type-air.vlajnost-indicator {
  background: #4E8D46;
  color: #fff;
  width: 100px;
  min-width: 70px;
  padding: 5px;
  min-height: 0;
}
.map-widgets .icon-widget .icon {
  float: left;
  width: 23px;
  height: 23px;
  margin-right: 0;
  position: relative;
  margin-bottom: 0;
  transition: 0.3s ease;
}
.map-widgets .icon-widget .icon svg {
  width: 23px;
  height: 23px;
  margin-top: 0;
  margin-left: 0;
}
.map-widgets .icon-widget .icon .status-indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 79%;
  top: 70%;
  background: #999;
  border-radius: 50%;
  line-height: 11px;
  font-size: 8px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  transition: 0.3s ease;
}
.map-widgets .icon-widget .icon .status-indicator.active {
  background: #006ce4;
}
.blur #main-navigation,
.blur #main-toolbar {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.blur .map-view {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}
._modal {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  text-align: center;
  display: block;
  touch-action: pan-y;
  transition: opacity 0.3s ease;
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}
._modal ::-webkit-scrollbar-track {
  background-color: transparent;
  display: none;
}
._modal ::-webkit-scrollbar {
  background-color: transparent;
  display: none;
}
._modal ::-webkit-scrollbar-thumb {
  background-color: transparent;
  display: none;
}
._modal.active {
  opacity: 1;
}
._modal.active .modal-scroll {
  transform: translateY(0);
  pointer-events: all;
}
._modal.active .center {
  pointer-events: all;
}
._modal .modal-scroll {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: transform 0.2s ease, opacity 0.3s ease;
}
._modal .modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
}
._modal .modal-content {
  max-width: 85%;
  width: auto;
  display: block;
  min-height: 100px;
  background: #fff;
  padding: 60px 15px;
  border-radius: 20px;
  margin: 50px auto;
  font-size: 15px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
}
._modal .modal-content .btn {
  padding: 12px 20px;
  border: 1px solid #ccc;
}
._modal .modal-content .time-hour-min-widget .form-control {
  border: 1px solid #ccc;
}
._modal .modal-content .icon-type-pir .btn {
  padding: 10px 11px;
  height: 45px;
}
._modal .modal-content .icon-type-pir .input-group-addon {
  background: #fafafa;
}
._modal .modal-content .icon-type-window.btn-group .btn {
  border: 1px solid #ccc;
  padding: 12px 15px;
}
._modal .modal-content .currentTemp .text-danger,
._modal .modal-content .currentTemp .text-info,
._modal .modal-content .currentTemp .text-warning {
  text-align: center;
  font-size: 25px;
}
._modal .modal-content .controlWantedTemp .btn,
._modal .modal-content .controlWantedTemp .btn:hover,
._modal .modal-content .controlWantedTemp .btn:active,
._modal .modal-content .controlWantedTemp btn:focus,
._modal .modal-content .controlWantedTemp .btn:visited {
  border-radius: 50%;
  background: #fff;
  width: 34px;
  line-height: 2px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  height: 34px;
  padding: 0;
  display: table-cell;
  font-weight: normal;
  text-align: center;
  white-space: normal;
  margin: 0 20px;
}
._modal .modal-content .controlWantedTemp .form-control,
._modal .modal-content .controlWantedTemp .form-control:hover,
._modal .modal-content .controlWantedTemp .form-control:active,
._modal .modal-content .controlWantedTemp .form-control:focus {
  border: 1px solid #ccc;
  border-radius: 20px;
  color: #4c94d4;
  height: 40px;
  text-align: center;
  font-size: 25px;
}
._modal .label-success {
  background-color: #218fdd;
}
#curton-horizontal-slider {
  margin: 0 30px;
  height: 12px;
}
#curton-horizontal-slider .noUi-connect {
  background: #f0a700;
}
#curton-horizontal-slider .noUi-handle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
#curton-horizontal-slider .noUi-handle:focus,
#curton-horizontal-slider .noUi-handle:active {
  outline: none !important;
}
#curton-horizontal-slider .noUi-handle:before,
#curton-horizontal-slider .noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  background: #E8E7E6;
  height: 8px;
  left: 6px;
  top: 6px;
  width: 1px;
}
#curton-horizontal-slider .noUi-handle:after {
  left: 10px;
}
#dimming-vetical-slider,
.real_dimming-vetical-slider {
  height: 200px;
  width: 100px;
  border-radius: 10px;
  margin: 20px auto;
  background: #ececec;
  border-color: #ececec;
}
#dimming-vetical-slider .noUi-pips-vertical,
.real_dimming-vetical-slider .noUi-pips-vertical {
  left: 0;
  width: 100%;
  padding: 0;
  border-radius: 10px;
}
#dimming-vetical-slider .noUi-pips-vertical .noUi-value-large,
.real_dimming-vetical-slider .noUi-pips-vertical .noUi-value-large {
  display: none !important;
}
#dimming-vetical-slider .noUi-pips-vertical .noUi-marker-vertical,
.real_dimming-vetical-slider .noUi-pips-vertical .noUi-marker-vertical {
  width: 100%;
  background: #fff;
}
#dimming-vetical-slider .noUi-handle,
.real_dimming-vetical-slider .noUi-handle {
  width: 112px;
  opacity: 0;
  height: 20px;
}
#dimming-vetical-slider .noUi-handle:after,
.real_dimming-vetical-slider .noUi-handle:after {
  top: 12px !important;
}
#dimming-vetical-slider .noUi-handle:after,
.real_dimming-vetical-slider .noUi-handle:after,
#dimming-vetical-slider .noUi-handle:before,
.real_dimming-vetical-slider .noUi-handle:before {
  width: 84px;
  height: 1px;
  margin-left: 6px;
  left: 6px;
  top: 6px;
}
#dimming-vetical-slider .noUi-connect,
.real_dimming-vetical-slider .noUi-connect {
  background: #848484;
  opacity: 0.8;
}
#network-status-modal._modal {
  position: fixed;
  pointer-events: none;
}
#network-status-modal._modal .modal-content {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#006ce4+0,00c9d8+100 */
  background: #006ce4;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #006ce4 0%, #00c9d8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006ce4', endColorstr='#00c9d8', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  position: fixed;
  border-radius: 0;
  margin: 0;
}
.hidden {
  display: none;
}
#login-screen {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#006ce4+0,00c9d8+100 */
  background: #006ce4;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #006ce4 0%, #00c9d8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006ce4', endColorstr='#00c9d8', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 30px 40px;
  color: #fff;
}
#login-screen.active {
  display: flex;
}
#login-screen .form-control {
  background: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  color: #fff;
}
#login-screen .form-control:hover,
#login-screen .form-control:focus,
#login-screen .form-control:active {
  background: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  color: #fff;
}
#login-screen .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
#login-screen .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
#login-screen .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}
#login-screen .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
#login-screen .btn {
  background: #fff;
  border-radius: 15px;
  padding: 10px 45px;
  margin-top: 15px;
}
#loading-screen {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#006ce4+0,00c9d8+100 */
  background: #006ce4;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #006ce4 0%, #00c9d8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006ce4', endColorstr='#00c9d8', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 40px;
  color: #fff;
  pointer-events: all;
}
#loading-screen.active {
  display: flex;
}
#system-screen {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#006ce4+0,00c9d8+100 */
  background: #006ce4;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #006ce4 0%, #00c9d8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #006ce4 0%, #00c9d8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006ce4', endColorstr='#00c9d8', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 40px;
}
#system-screen.active {
  display: flex;
}
.icon-widget.icon-type-params .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_1_);
}
.icon-widget.icon-type-params .st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_2_);
}
.icon-widget.icon-type-params .st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_3_);
}
.icon-widget.icon-type-params .st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_4_);
}
.icon-widget.icon-type-params .st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
}
.icon-widget.icon-type-params .st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #8DC63F;
}
.icon-widget.icon-type-params .st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FF0033;
}
.icon-widget.icon-type-params svg {
  position: relative;
}
.icon-widget.icon-type-params .text {
  position: absolute;
  z-index: 6;
  color: #fff;
  font-size: 12px;
  width: 100%;
  height: 140px;
  top: 40px;
}
.icon-widget.icon-type-params .text .title {
  text-transform: uppercase;
  top: 0;
  position: absolute;
  margin-top: -20px;
  text-align: center;
  width: 100%;
  font-size: 16px;
}
.icon-widget.icon-type-params .text .current {
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  left: 52px;
  margin-top: 2px;
  position: absolute;
  display: flex;
  align-items: center;
}
.icon-widget.icon-type-params .text .current div:first-child {
  font-size: 10px;
  text-align: right;
  margin-right: 5px;
}
.icon-widget.icon-type-params .text .current div:last-child {
  font-size: 16px;
  font-weight: bold;
}
.icon-widget.icon-type-params .text .source {
  text-transform: uppercase;
  top: 20px;
  right: 25px;
  position: absolute;
}
.icon-widget.icon-type-params .text .goren {
  text-transform: uppercase;
  top: 15px;
  left: 45px;
  position: absolute;
  width: 100%;
  line-height: 1.1em;
}
.icon-widget.icon-type-params .text .goren.active {
  color: #006ce4;
}
.icon-widget.icon-type-params .text .dolen {
  text-transform: uppercase;
  bottom: 0;
  left: 45px;
  position: absolute;
  width: 100%;
  line-height: 1.1em;
}
.icon-widget.icon-type-params .text .dolen.active {
  color: #006ce4;
}
.icon-widget.icon-type-params .container-fill {
  z-index: 4;
  position: absolute;
  left: 0;
  bottom: 35px;
  right: 0;
  z-index: 5;
}
.icon-widget.icon-type-params .container-fill .mask {
  overflow: hidden;
  height: 0px;
  transform: rotate(180deg);
}
.icon-widget.icon-type-params .container-fill .mask .inside {
  background: url(../img/buferen-sud-fill-bg.svg) center center no-repeat;
  height: 145px;
  transform: rotate(-180deg);
}
._week_caledar-scroll {
  width: 100%;
}
._week_caledar-scroll ::-webkit-scrollbar-track {
  background-color: transparent;
}
._week_caledar-scroll ::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background-color: transparent;
}
._week_caledar-scroll ::-webkit-scrollbar-thumb {
  background-color: transparent;
  background: transparent;
}
._week_caledar-scroll .month-name {
  border-right: 1px solid #666;
  padding: 10px 15px;
  background: #fafafa;
  text-align: left;
  font-size: 14px;
  color: #333;
}
._week_caledar-scroll .days {
  display: flex;
  align-items: stretch;
  margin-left: -1px;
}
._week_caledar-scroll .days .day {
  border: 1px solid #ccc;
  width: 100%;
  height: 83px;
  border-right: 0;
  padding: 5px;
  overflow-y: auto;
}
._week_caledar-scroll .days .day .programs-popover {
  display: none;
  padding: 10px 15px;
  color: #665;
  border: 1px solid #ccc;
  background: #fff;
  padding: 5px;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 150px;
  z-index: 2;
}
._week_caledar-scroll .days .day .programs-popover .title {
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  color: #666;
}
._week_caledar-scroll .days .day .programs-popover select {
  display: block;
  max-width: 90%;
  margin: 5px auto;
  border: 1px solid #ccc;
  background: #fff;
  padding: 5px 8px;
  margin-bottom: 10px;
}
._week_caledar-scroll .days .day .programs-popover.active {
  display: block;
}
._week_caledar-scroll .days .day._today {
  background: #fff5d4;
}
._week_caledar-scroll .days .day._weekend .number .name {
  font-weight: bold;
  color: #cc3333;
}
._week_caledar-scroll .days .day .number {
  margin-bottom: 2px;
  color: #666;
}
._week_caledar-scroll .days .day .number .name {
  float: right;
}
._week_caledar-scroll .days .day .event {
  text-align: center;
}
._week_caledar-scroll .days .day .event .event-item {
  display: block;
  max-width: 100%;
  padding: 3px 7px;
  color: #fff;
  background: #333;
  margin: 0 auto;
  border-radius: 5px;
  font-size: 11px;
  margin-bottom: 3px;
}
._week_caledar-scroll .days .day .btn-add-event {
  font-weight: bold;
  font-size: 14px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 0;
  border-radius: 50%;
  background: #fff;
  position: relative;
}
._week_caledar-scroll .days .day .btn-add-event:before {
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230%;
  height: 230%;
  display: block;
  position: absolute;
  border-radius: 50%;
  z-index: 0;
  background: transparent;
}
._week_caledar-scroll .scrollable {
  overflow-y: scroll;
  display: flex;
  align-items: stretch;
}
body.edit-mode #main-navigation {
  background: #ff8822;
  background: linear-gradient(180deg, #ff7700 0%, #ff8822);
}
body.edit-mode .notifications-view .metereo-block {
  background: #ff8822;
  background: linear-gradient(0deg, #ff7700 0%, #ff8822);
  background: -webkit-linear-gradient(0deg, #ff7700 0%, #ff8822);
}
body.edit-mode #main-toolbar .icon.active {
  box-shadow: inset 0 4px #ff7700;
  background: #ff8822;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, #ff7700 0%, #ff8822);
}
body.edit-mode #main-toolbar {
  color: #ff8822;
}
body.edit-mode .map-view {
  background: #ff8822;
  background: linear-gradient(0deg, #ff7700 0%, #ff8822);
}
body.edit-mode .mode-edit-active .icon-widget {
  border: 5px solid #ff8822 !important;
}
.portrait .map-view {
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  margin-top: calc(var(--safe-area-inset-top));
}
.portrait #main-navigation {
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  padding-top: calc(5px + var(--safe-area-inset-top));
}
.portrait .houses-view,
.portrait .floors-view,
.portrait .notifications-view {
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  top: calc(var(--safe-area-inset-top));
}
